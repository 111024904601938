import * as React from "react";
import { useEffect, useState } from "react";

import * as Yup from "yup";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import moment from "moment-timezone";
import { Page } from "../components/Page";
import DefaultLayout from "../layouts/default";
import { EventApi, EventAttributes } from "../api";
import { defaultAPIConfig } from "../config/defaultApiConfig";
import { Tickets } from "../components/Tickets";
import { InputField } from "../components/InputField";
import Formstyle from "../components/Formstyle";

import EventData from "../components/Events/EventData";
import Grid75 from "../components/Grid.tsx/Grid75";
import GreyBox from "../components/GreyBox";
import { InhaltElem } from "../components/Text/SmallerInhalt";
import ContentWrapper from "../components/Wrappers/ContentWrapper";
import Events from "../components/Events";
import SmallerMainTitle from "../components/Text/SmallerMainTitle";
import { colors } from "../styles/variables";
import SectionTitle from "../components/Text/SectionTitle";
import Komittees from "../components/Komittees";
import Sponsors from "../components/Sponsors";
import Button from "../components/Buttons/Button";
import { DebugForm } from "../components/DebugForm";
import EventDates from "../components/Events/EventDates";
import { TextareaField } from "../components/TextareaField";

interface EventTemplateProps {
  pageContext: {
    attributes: EventAttributes;
    id: string;
  };
}

const SubmitButton = styled.button`
  width: max-content;
  min-width: 200px;
  display: block;
  padding: 10px 10px;
  text-transform: uppercase;
  text-align: center;
  font: 23px / 28px "Slate Regular";
  background: ${colors.blue};
  margin: inherit auto;
  color: white;
  border-radius: 10px;
  margin: 0 auto;
  text-decoration: none !important;
  transition: all 0.2s;
  outline: none;
  border: 0;

  &:hover {
    background: ${colors.lightBlue};
    cursor: pointer;
  }
`;

const TicketSchema = Yup.object().shape({
  name: Yup.string()
    .required("This field is required.")
    .min(2, "Please enter min. 2 characters."),
  firstName: Yup.string()
    .required("This field is required.")
    .min(2, "Please enter min. 2 characters."),
  address: Yup.string()
    .required("This field is required.")
    .min(2, "Please enter min. 2 characters."),
  zip: Yup.string()
    .required("This field is required.")
    .min(2, "Please enter min. 2 characters."),
  city: Yup.string()
    .required("This field is required.")
    .min(2, "Please enter min. 2 characters."),
  phone: Yup.string()
    .required("This field is required.")
    .min(2, "Please enter min. 2 characters."),
  email: Yup.string()
    .required("This field is required.")
    .email("Invalid email format")
    .min(2, "Please enter min. 2 characters."),
  termsAndConditionsRead: Yup.boolean().oneOf([true]),
  successRedirectUrl: Yup.string(),
  failedRedirectUrl: Yup.string(),
  cancelRedirectUrl: Yup.string(),
});

const api = new EventApi(defaultAPIConfig());

const BookingTemplate: React.FC<EventTemplateProps> = (props) => {
  const [tickets, setTickets] = useState([]);
  // All Data which can be prepared at build time
  const {
    pageContext: {
      attributes: {
        description,
        date,
        labels,
        freeText: { text },
        ticketing: { ticketCategories },
      },
      id,
    },
  } = props;
  // All realtime data (ticket availability, ..)
  useEffect(() => {
    const fetchEvent = async () => {
      const event = await api.eventIdGet({ id: parseInt(id, 10) });
      if (event.data?.attributes?.ticketing?.ticketCategories) {
        // @ts-ignore
        setTickets(event.data.attributes.ticketing.ticketCategories);
      }
    };

    fetchEvent();
  }, []);

  const ticketArray = ticketCategories.map(({ ticketCategoryId }) => ({
    ticketCategoryId: parseInt(ticketCategoryId, 10),
    quantity: 0,
  }));

  const TicketInitialValues = {
    eventId: id,
    tickets: ticketArray,
    name: "",
    firstName: "",
    address: "",
    zip: "",
    city: "",
    phone: "",
    email: "",
    company: "",
    remarks: "",
    termsAndConditionsRead: true,
    successRedirectUrl: `${process.env.GATSBY_BASE_REDIRECT_URL}/event/${id}/success/`,
    failedRedirectUrl: `${process.env.GATSBY_BASE_REDIRECT_URL}/event/${id}/failed/`,
    cancelRedirectUrl: `${process.env.GATSBY_BASE_REDIRECT_URL}/event/${id}/cancelled/`,
    ticketRadio: "",
  };

  const eventName = props.pageContext.attributes.agenda[0].name || "";
  const eventType = props.pageContext.attributes.type || "";
  let eventPlace = "";
  if (props.pageContext.attributes.labels?.length) {
    eventPlace = props.pageContext.attributes.labels[0].name;
  }
  const eventDoorsOpening = props.pageContext.attributes.doorsOpening || "";
  const eventDate = props.pageContext.attributes.date || "";
  const eventEndDate = props.pageContext.attributes.end || "";
  const time =
    moment(props.pageContext.attributes.doorsOpening)
      .tz("Europe/Zurich")
      .format("HH:mm - ") +
    moment(props.pageContext.attributes.end)
      .tz("Europe/Zurich")
      .format(" HH:mm");
  return (
    <DefaultLayout siteSlug={date?.toString()}>
      <Helmet>
        <title>{eventName} - STEP</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={description} />
      </Helmet>
      <Page>
        <ContentWrapper>
          <EventData
            type={eventType}
            date={eventDate}
            endDate={eventEndDate}
            relatedEvents={props.pageContext.relationships.events.data}
            place={eventPlace}
            showtime={props.pageContext.attributes.showtime}
          />

          <SmallerMainTitle color={eventType}>{eventName}</SmallerMainTitle>

          <Grid75>
            <InhaltElem>
              {moment(
                props.pageContext.attributes.ticketing?.closingTime
              ).isAfter(new Date()) &&
              props.pageContext.attributes.ticketing?.active ? (
                <>
                  <Formstyle>
                    <Formik
                      validationSchema={TicketSchema}
                      initialValues={TicketInitialValues}
                      onSubmit={(values, actions) => {
                        // create tickets with string quantities
                        /**
                        const ticketQuantitiesAsString = values.tickets.map(
                          (ticket) => ({
                            quantity: ticket.quantity.toString(),
                            ticketCategoryId: ticket.ticketCategoryId,
                          })
                        );

                         */
                        const radioAsTicketQuantity = values.tickets.map(
                          (ticket) => {
                            if (ticket.ticketCategoryId == values.ticketRadio) {
                              return {
                                quantity: "1",
                                ticketCategoryId: ticket.ticketCategoryId,
                              };
                            }

                            return {
                              quantity: "0",
                              ticketCategoryId: ticket.ticketCategoryId,
                            };
                          }
                        );
                        const makeBooking = async () => {
                          const payload = {
                            ticketPurchaseRequest: {
                              data: {
                                type: "ticketpurchaserequest",
                                attributes: {
                                  ...values,
                                  tickets: radioAsTicketQuantity,
                                },
                              },
                            },
                          };

                          const ticketPurchaseResponse =
                            await api.ticketpurchasePost(payload, {
                              headers: {
                                accept: "application/vnd.api+json",
                                "content-type": "application/vnd.api+json",
                              },
                            });

                          if (
                            ticketPurchaseResponse.data?.attributes?.paymentUrl
                          ) {
                            // if request would work:
                            window.location =
                              ticketPurchaseResponse.data?.attributes?.paymentUrl;
                          }
                        };

                        makeBooking();

                        // alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                      }}
                    >
                      {() => (
                        <Form>
                          <div>{tickets && <Tickets tickets={tickets} />}</div>
                          <InputField name="name" label="Family name *" />
                          <InputField name="firstName" label="First name *" />
                          <InputField name="company" label="Company" />
                          <InputField name="address" label="Address *" />
                          <InputField name="zip" label="Zip *" />
                          <InputField name="city" label="City *" />
                          <InputField name="phone" label="Phone *" />
                          <InputField name="email" label="Mail *" />
                          <TextareaField name="remarks" label="Comments" />
                          <SubmitButton type="submit">BOOK NOW</SubmitButton>
                          {/** <DebugForm /> */}
                        </Form>
                      )}
                    </Formik>
                  </Formstyle>
                </>
              ) : (
                <>Registration already closed.</>
              )}

              <br />
              <br />
            </InhaltElem>

            <GreyBox>
              <InhaltElem>
                <b>Date</b>
                <br />
                <EventDates
                  relatedEvents={props.pageContext.relationships.events.data}
                  date={eventDate}
                  showtime={props.pageContext.attributes.showtime}
                  end={eventEndDate}
                />
                <br />
                <b>Venue</b>
                <br />
                {props.pageContext.relationships.location.data.attributes.name}
                <br />
                {
                  props.pageContext.relationships.location.data.attributes
                    .address
                }
                <br />
                {
                  props.pageContext.relationships.location.data.attributes.zip
                }{" "}
                {props.pageContext.relationships.location.data.attributes.city}
                <br />
                {
                  props.pageContext.relationships.location.data.attributes
                    .country
                }
                <br />
                {props.pageContext.relationships.location.data.attributes
                  .url !== "" ? (
                  <>
                    <a
                      target="_blank"
                      href={
                        props.pageContext.relationships.location.data.attributes
                          .url
                      }
                      rel="noreferrer"
                    >
                      link to venue
                    </a>
                    <br />
                  </>
                ) : (
                  <></>
                )}
                <br />
                {(props.pageContext.attributes.reservations?.active ||
                  props.pageContext.attributes.ticketing?.active) && (
                  <>
                    <b>Registration by</b>
                    <br />
                    {moment(props.pageContext.attributes.ticketing?.closingTime)
                      .tz("Europe/Zurich")
                      .format("dddd, DD MMMM YYYY")
                      .toUpperCase()}
                    <br />
                    <br />
                  </>
                )}
                {text && (
                  <>
                    <b>CPD</b>
                    <br />
                    {text}
                    <br />
                    <br />
                  </>
                )}
                <b>Fee</b>
                <br />
                {tickets &&
                  tickets.map((ticket) => (
                    <div>
                      {ticket.title}{" "}
                      {ticket.admission == "0.00" ? (
                        <span>free of charge</span>
                      ) : (
                        `${ticket.admission} CHF`
                      )}{" "}
                      {ticket.info}{" "}
                    </div>
                  ))}
                <br />
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.pageContext.attributes.shortDescription,
                  }}
                />
              </InhaltElem>
            </GreyBox>
          </Grid75>

          <Sponsors labels={labels} />
        </ContentWrapper>
      </Page>
    </DefaultLayout>
  );
};

export default BookingTemplate;
