import * as React from "react";
import { Field, useField } from "formik";

type InputFieldProps = {
  name: string;
  value: string;
  id: string;
};

export const RadioField = (props: InputFieldProps) => {
  const { name, id, value } = props;

  return (
    <>
      <Field type="radio" name={name} value={value} id={id} />{" "}
    </>
  );
};
