import * as React from "react";
import { NumberField } from "./NumberField";
import { RadioField } from "./RadioField";

interface TicketsProps {
  tickets: Ticket[];
}

type Ticket = {
  admission: string;
  available: boolean;
  info: string;
  ticketCategoryId: string;
  title: string;
};

export const Tickets: React.FC<TicketsProps> = ({ tickets }) => (
  <div>
    <h2>Tickets</h2>
    {tickets.map((ticket, index) => {
      const { admission, available, info, ticketCategoryId, title } = ticket;
      return (
        <div key={ticketCategoryId}>
          {available && (
            <RadioField
              name="ticketRadio"
              value={`${ticketCategoryId}`}
              id={index}
            />
          )}
          <label htmlFor={index}>
            {title} -{" "}
            {admission == "0.00" ? (
              <span>free of charge</span>
            ) : (
              `${admission} CHF`
            )}{" "}
            {available ? <span>(available)</span> : <span>(sold out)</span>}{" "}
            {info}
          </label>
        </div>
      );
    })}
  </div>
);
